import { CalendarOutlined, SnippetsOutlined, UserOutlined } from "@ant-design/icons"
import { IDrawerSchemaCategory } from "interfaces/sections/main/drawer"

export const MainDrawerSchema: IDrawerSchemaCategory = {
    name: "Основное",
    items: [
        {
            name: "Профиль",
            icon: UserOutlined,
            path: "/user/profile"
        },
        {
            name: "Мои заказы",
            icon: CalendarOutlined,
            path: "/user/orders"
        },
        {
            name: "Мои операции",
            icon: SnippetsOutlined,
            path: "/user/transactions"
        }
    ]
}

export const AdminDrawerSchema: IDrawerSchemaCategory = {
    name: "Администратор",
    items: [
        {
            name: "Все пользователи",
            icon: UserOutlined,
            path: "/admin/users"
        },
        {
            name: "Все заказы",
            icon: CalendarOutlined,
            path: "/admin/orders"
        }
    ]
}
