import MainWrapper from "sections/main/MainWrapper"

import { lazy } from "react"
import { Outlet } from "react-router-dom"
import { Loadable } from "components"
import { RestrictGuard } from "context/guards"

const UserProfile = Loadable(lazy(() => import("pages/user/profile")))
const UserTransactions = Loadable(lazy(() => import("pages/user/transactions")))

const UserOrders = Loadable(lazy(() => import("pages/user/orders")))

const MainRoutes = {
    path: "/user",
    element: (
        <RestrictGuard minRole={1} redirect="/">
            <MainWrapper>
                <Outlet />
            </MainWrapper>
        </RestrictGuard>
    ),
    children: [
        {
            path: "profile",
            element: <UserProfile />
        },
        {
            path: "transactions",
            element: <UserTransactions />
        },
        {
            path: "orders",
            element: <UserOrders />
        },
    ]
}

export default MainRoutes
