import { ICenterFiltered, ICenterParsed, ICentersParsed } from "interfaces/main/center"

const formatCenters = (centers: ICenterFiltered[]): ICentersParsed => {
    const parsed: ICentersParsed = {}

    for (let i in centers) {
        const center = centers[i]

        if (center.country && center.value) {
            if (!parsed.hasOwnProperty(center.country)) {
                parsed[center.country] = {}
            }

            const centerParsed: ICenterParsed = { name: center.name, categories: {} }

            center.categories?.map((x) => {
                if (x.value) {
                    centerParsed.categories![x.value] = { name: x.name, price: x.price, fastPrice: x.fastPrice }
                }
            })

            parsed[center.country][center.value] = centerParsed
        }
    }

    return parsed
}

export default formatCenters
