import { ITableContentProps } from "interfaces/components/table"
import { Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

export const TableContent = ({ items, headers }: ITableContentProps) => {
    return (
        <TableContainer>
            <MUITable>
                <TableHead>
                    <TableRow>
                        {Object.keys(headers).map((x) => (
                            <TableCell key={x} align={headers[x].align || "left"} style={{ whiteSpace: "nowrap" }}>
                                {headers[x].name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items &&
                        items.map((item, i) => (
                            <TableRow key={i}>
                                {Object.keys(item).map((x) =>
                                    headers[x] ? (
                                        <TableCell key={x} align={headers[x].align || "left"} style={{ whiteSpace: "nowrap" }}>
                                            {item[x]}
                                        </TableCell>
                                    ) : (
                                        ""
                                    )
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}
