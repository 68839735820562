import AuthWrapper from "sections/auth/AuthWrapper"

import { Loadable } from "components"
import { GuestGuard } from "context/guards"
import { lazy } from "react"
import { Outlet } from "react-router-dom"

const AuthLogin = Loadable(lazy(() => import("pages/auth/login")))
const AuthRegister = Loadable(lazy(() => import("pages/auth/register")))
const AuthReset = Loadable(lazy(() => import("pages/auth/reset")))

const AuthRoutes = {
    path: "/",
    element: (
        <GuestGuard>
            <AuthWrapper>
                <Outlet />
            </AuthWrapper>
        </GuestGuard>
    ),
    children: [
        {
            path: "/",
            element: <AuthLogin />
        },
        {
            path: "login",
            element: <AuthLogin />
        },
        {
            path: "register/:invite?",
            element: <AuthRegister />
        },
        {
            path: "reset",
            element: <AuthReset />
        }
    ]
}

export default AuthRoutes
